import { render, staticRenderFns } from "./UISwitch.vue?vue&type=template&id=6708a39a&scoped=true&"
import script from "./UISwitch.vue?vue&type=script&lang=js&"
export * from "./UISwitch.vue?vue&type=script&lang=js&"
import style0 from "./UISwitch.vue?vue&type=style&index=0&id=6708a39a&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../../../.yarn/$$virtual/vue-loader-virtual-2fffd51942/0/cache/vue-loader-npm-15.9.3-4ca89c7e4b-bc7d4c63e0.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6708a39a",
  null
  
)

export default component.exports