<template>
    <div class="location-gateway">
      <ContentBlock :loading="isLoading">
        <div slot="instructions">
          <h5>{{ $t('Payment Options') }}</h5>
          <p>{{ $t('Set up how your customers will be able to pay when making reservations online. You can have up to 3 payments options enabled at the same time: PayPal, credit card (Square or Stripe, but not both at the same time), and in-store payments.') }}</p>
        </div>

        <div class="gateways" v-if="location.id">
          <ValidationObserver ref="paypalValidObserver" v-slot="{ pristine, invalid }" :disabled="ignoreValidationForParent">
          <div class="paypal">
            <img src="~@/assets/PayPal.svg" alt="PayPal" />
            <UIButton
              v-if="!paypal"
              secondary
              class="ml-auto"
              :style="{opacity: expanded ? 0 : 1}"
              @click="expandPaypalOptions()"
            >{{ $t('Connect PayPal') }}</UIButton>
            <UISwitch
              v-else
              v-show="!expanded"
              :title="$t('Enable PayPal')"
              :value="paypal.bookingEnabled"
              @change="editGateway('paypal', 'bookingEnabled', !paypal.bookingEnabled)"
            />
            <UIButton
              tiny
              v-if="paypal"
              icon="trash"
              @click="disconnect('paypal')"
              class="disconnect"
            >{{ $t('Disconnect PayPal') }}</UIButton>
              <UIExpandable
                :expanded="expanded"
                class="paypalgrid"
                style="grid-column: 1/-1;width:100%"
              >
                <!-- <div class="flex"> -->
                <UIInput class="w-100" :title="$t('Client ID')" v-model="paypalClientId" required rules="required" />
                <UIInput class="w-100" :title="$t('Client Secret')" v-model="paypalClientSecret" required rules="required" />
                <!-- </div> -->
                <div class="tr mt4 w-100" style="grid-column: 1/-1">
                  <UIButton ghost class="mr4" @click="closePaypalOptions()">{{ $t('Cancel') }}</UIButton>
                  <UIButton
                    primary
                    class
                    @click="savePaypalSecrets"
                    :disabled="pristine || invalid"
                  >{{ !paypal ? $t('Save') : $t('Update') }}</UIButton>
                </div>
              </UIExpandable>
          </div>
          </ValidationObserver>
          <div v-if="square" class="square">
            <img src="/square.svg" alt="Square" />
            <UISelect
              :title="$t('Square Location')"
              id="squareLocation"
              :value="square.squareLocationId"
              @change="editGateway('square', 'squareLocationId', $event.target.value)"
            >
              <option
                v-for="squareLocation in square.squareLocations"
                :key="squareLocation.id"
                :value="squareLocation.id"
                :UISelected="square.squareLocationId === squareLocation.id"
              >{{ squareLocation.name }}</option>
            </UISelect>
            <UISwitch
              :title="$t('Enable Square')"
              id="squareEnabled"
              :value="square.bookingEnabled"
              @change="editGateway('square', 'bookingEnabled', !square.bookingEnabled)"
            />
            <UIButton
              tiny
              icon="trash"
              @click="disconnect('square')"
              class="disconnect"
            >{{ $t('Disconnect Square') }}</UIButton>
          </div>

          <div class="square" v-else>
            <img src="/square.svg" alt="Square" />
            <UIButton
              secondary
              :href="apiURL + '/oauth/square?locationId=' + location.id + '&token=' + token"
              class="connect-square"
            >{{ $t('Connect Square') }}</UIButton>
          </div>

          <div v-if="stripe" class="stripe">
            <img src="/stripe.svg" alt="Stripe" />
            <UISwitch
              :title="$t('Enable stripe')"
              id="stripeEnabled"
              :value="stripe.bookingEnabled"
              @change="editGateway('stripe', 'bookingEnabled', !stripe.bookingEnabled)"
            />
            <UIButton
              tiny
              icon="trash"
              @click="disconnect('stripe')"
              class="disconnect"
            >{{ $t('Disconnect Stripe') }}</UIButton>
          </div>

          <div class="stripe" v-else>
            <img src="/stripe.svg" alt="Stripe" />
            <UIButton
              secondary
              :href="apiURL + '/oauth/stripe?locationId=' + location.id + '&token=' + token"
              class="connect-stripe"
            >{{ $t('Connect Stripe') }}</UIButton>
          </div>

          <div v-if="pos" class="pos">
            <UISwitch
              :title="$t('Enable In Store Payments')"
              :instructions="$t('This will allow your customers to defer payment until they arrive at your store.')"
              :value="pos.bookingEnabled"
              @change="editGateway('pos', 'bookingEnabled', !pos.bookingEnabled)"
            />
          </div>
        </div>

        <div v-else>
          <p
            class="f5"
          >{{ $t('Once your location is created you will be able to add payment methods.') }}</p>
        </div>
      </ContentBlock>
    </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import ContentBlock from '@/core/shared/components/ContentBlock';
import deepmerge from 'deepmerge';

import UISwitch from '@/core/shared/components/ui/UISwitch';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIInput from '@/core/shared/components/ui/UIInput';
import UIExpandable from '@/core/shared/components/ui/UIExpandable';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    ContentBlock,
    UISwitch,
    UIButton,
    UIInput,
    UIExpandable,
    UISelect,
    ValidationObserver,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
    ignoreValidationForParent: {
      type: Boolean,
      default: false,
    },
  },
})
export default class LocationGateways extends Vue {
  expanded = false
  paypalClientId = null
  paypalClientSecret = null
  isLoading = null

  get apiURL () {
    return `//${window.sbvrenv.API_URL}/v1`;
  }

  get pos () {
    return this.location.gateways.find(gateway => gateway.type === 'pos');
  }

  get square () {
    return this.location.gateways.find(gateway => gateway.type === 'square');
  }

  get paypal () {
    return this.location.gateways.find(gateway => gateway.type === 'paypal');
  }

  get stripe () {
    return this.location.gateways.find(gateway => gateway.type === 'stripe');
  }

  get token () {
    return this.$store.getters.token;
  }

  editGateway (service, property, value) {
    const newGateways = deepmerge([], this.location.gateways);
    const index = newGateways.findIndex(gateway => gateway.type === service);

    newGateways[index][property] = value;

    this.$emit('updateGateways', newGateways);
  }

  async savePaypalSecrets () {
    this.isLoading = true;
    try {
      await this.$store.dispatch('storeGateway', {
        type: 'paypal',
        bookingEnabled: true,
        clientId: this.paypalClientId,
        clientSecret: this.paypalClientSecret,
        location: {
          id: this.location.id,
        },
      });
      this.closePaypalOptions();
      this.paypalClientId = null;
      this.paypalClientSecret = null;
      this.$emit('updateGateways', this.$store.getters['entities/Location'](this.location.id).gateways);
    } finally {
      this.isLoading = false;
    }
  }

  disconnect (service) {
    const newGateways = deepmerge([], this.location.gateways);
    const index = newGateways.findIndex(gateway => gateway.type === service);
    this.$emit('updateGateways', [
      ...newGateways.slice(0, index),
      ...newGateways.slice(index + 1),
    ]);
  }

  expandPaypalOptions () {
    this.expanded = true;
    this.$emit('paypalExpanded');
  }

  closePaypalOptions () {
    this.expanded = false;
    this.$emit('paypalClosed');
  }
}
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";

.gateways {
  display: grid;
  grid-gap: var(--spacingLg);
  text-align: left;

  & .paypal,
  & .square,
  & .stripe {
    display: grid;
    grid-gap: var(--spacingMd);
    justify-items: start;
    align-items: center;

    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    & img {
      width: 10rem;
    }

    & a {
      justify-self: end;
    }

    & .ui-switch {
      @media (--tablet) {
        justify-self: end;
      }
    }

    & .disconnect {
      @media (--tablet) {
        grid-column: 2 / 3;
        justify-self: end;
      }
    }
  }

  & .paypalgrid {
    display: grid;
    grid-gap: var(--spacingMd);
    justify-items: start;
    align-items: center;
    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  & .square {
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    & a {
      grid-column: 3 / 4;
    }

    & .disconnect {
      @media (--tablet) {
        grid-column: 3 / 4;
      }
    }
  }
}
</style>
