<script>
import Vue from 'vue';
import ModelMixin from './ModelMixin';
import Component from 'vue-class-component';
import { generateId } from '../../helpers/VariousHelper';
import { ValidationProvider } from 'vee-validate';

@Component({
  name: 'UISwitch',
  mixins: [
    ModelMixin,
  ],
  inheritAttrs: false,
  props: {
    title: {},
    instructions: {},
    required: {},
    rules: {
      type: [String, Object],
      default: '',
    },
    vid: {
      type: String,
      default: undefined,
    },
    fieldNameOverride: {
      type: String,
    },
  },
  components: {
    ValidationProvider,
  },
})
export default class UISwitch extends Vue {
  labelId = null
  mounted () {
    this.labelId = `checkbox${generateId()}`;
  }
}
</script>

<template>
  <ValidationProvider tag="div" :rules="rules" v-slot="{ errors }" :vid="vid" :name="!fieldNameOverride ? title : fieldNameOverride">
    <div class="ui-switch">
      <label>
        <input class="input" :id="labelId" type="checkbox" v-bind="$attrs" v-on="$listeners" v-model="internalValue" />
        <span class="switch"></span>
      </label>
      <div class="right">
        <label class="label pointer db" :for="labelId">
          <slot>{{ title }}{{required ? '*' : ''}}</slot>
          <p class="instructions" v-if="instructions || $scopedSlots.instructions">
            <slot name="instructions">{{ instructions }}</slot>
          </p>
        </label>
        <div class="additional">
          <slot name="additional"></slot>
        </div>
      </div>
    </div>
    <span class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<style scoped lang="postcss">
@import "../../styles";

.ui-switch {
  display: flex;
  & .input {
    display: none;
    & + .switch {
      display: block;
      position: relative;
      width: 5rem;
      height: 3rem;

      border: none;
      outline: none;
      appearance: none;
      text-shadow: none;
      box-shadow: none;
      cursor: pointer;
      background: none;

      &:before {
        display: block;
        visibility: visible;
        content: "";
        width: 4.9rem;
        height: 2.9rem;
        background: white;
        /* border: 0.2rem solid var(--colorGeyser); */
        border: 0.1rem solid var(--colorGeyser);
        box-shadow: 0 0 1px var(--colorGeyser);
        border-radius: 1.8rem;
        transition: background 0.3s ease;
      }

      &:after {
        @apply --panelDropShadow;

        display: block;
        position: absolute;
        visibility: visible;
        top: 0.1rem;
        left: 0rem;
        content: "";
        border-radius: 100%;
        width: 2.7rem;
        height: 2.7rem;
        background: var(--colorWhite);
        border: 0.1rem solid var(--colorGeyser);

        transition: transform ease-in-out 0.3s;
      }
    }
    &:checked {
      & + .switch:before {
        background: var(--colorInfo);
        border: none;
        width: 4.9rem;
        height: 2.9rem;
      }

      & + .switch:after {
        transform: translateX(2.1rem);
      }
    }

    &.disabled,
    &[disabled] {
      & + .switch {
        cursor: not-allowed !important;
        opacity: 0.6;
      }
    }
  }

  & .right {
    @apply --f8;
    padding-top: 0.7rem;
  }
  & .label,
  & .additional {
    padding-left: var(--spacingSm);
  }
  & .additional {
    margin-top: 0.7rem;
  }
  & .instructions {
    @apply --f7;
    margin: 0;
    margin-top: 0.7rem;
  }
}
.error {
  @apply --f7;
  color: var(--colorErrorFlat);
}
</style>
