import { computed } from '@vue/composition-api';
import deepmerge from 'deepmerge';

export function useSpringboardVRBooking (props, updateIntegrations) {
  const defaultSpringboardVRIntegration = {
    type: 'springboardvr',
    category: 'booking',
  };

  const springboardVRBookingIntegrationIndex = computed(() => {
    return props.location.integrations.findIndex(integration => {
      return integration.type === 'springboardvr';
    });
  });

  function toggleSpringboardVRBookingIntegration () {
    const integrations = [...props.location.integrations];
    const index = springboardVRBookingIntegrationIndex.value;
    if (index > -1) {
      integrations.splice(index, index + 1);
    } else {
      integrations.push(defaultSpringboardVRIntegration);
    }

    updateIntegrations(integrations);
  }

  return {
    springboardVRBookingIntegrationIndex,
    toggleSpringboardVRBookingIntegration,
  };
}

export function useCheckfrontBooking (props, updateIntegrations) {
  const defaultCheckfrontIntegration = (() => {
    const checkfrontIntegration = {
      type: 'checkfront',
      category: 'booking',
      integrationSettings: [
        {
          key: 'checkfront_domain',
          value: '',
        },
        {
          key: 'checkfront_api_key',
          value: '',
        },
        {
          key: 'checkfront_api_secret',
          value: '',
        },
      ],
    };
    props.location.experiences.forEach(experience => {
      checkfrontIntegration.integrationSettings.push({
        key: 'checkfront_skus',
        value: '[]',
        experience,
      });
    });
    return checkfrontIntegration;
  })();

  const checkfrontBookingIntegrationIndex = computed(() => {
    return props.location.integrations.findIndex(integration => {
      return integration.type === 'checkfront';
    });
  });

  const checkfrontBookingIntegration = computed(() => {
    return props.location.integrations.find(integration => {
      return integration.type === 'checkfront';
    });
  });

  function toggleCheckfrontBookingIntegration () {
    const integrations = deepmerge([], [...props.location.integrations]);
    const index = checkfrontBookingIntegrationIndex.value;
    if (index > -1) {
      integrations.splice(index, index + 1);
    } else {
      integrations.push(defaultCheckfrontIntegration);
    }

    updateIntegrations(integrations);
  }

  function getCheckfrontBookingIntegrationSetting (key) {
    return checkfrontBookingIntegration.value.integrationSettings.find(setting => {
      return setting.key === key;
    }).value;
  }

  function setCheckfrontBookingIntegrationSetting (key, value) {
    const integrations = deepmerge([], [...props.location.integrations]);
    const settingIndex = integrations[checkfrontBookingIntegrationIndex.value].integrationSettings.findIndex(setting => {
      return setting.key === key;
    });
    if (settingIndex > -1) {
      integrations[checkfrontBookingIntegrationIndex.value].integrationSettings[settingIndex].value = value;
    } else {
      integrations[checkfrontBookingIntegrationIndex.value].integrationSettings.push({
        key,
        value,
      });
    }

    updateIntegrations(integrations);
  }

  function getCheckfrontIntegrationSKUSetting (experienceId) {
    const checkfrontSKU = checkfrontBookingIntegration.value.integrationSettings.find(setting => {
      return setting.key === 'checkfront_skus' && setting.experience && setting.experience.id === experienceId;
    });
    if (checkfrontSKU) return JSON.parse(checkfrontSKU.value);
  }

  function setCheckfrontIntegrationSKUSetting (experienceId, value, add = false) {
    const integrations = deepmerge([], [...props.location.integrations]);
    const settingIndex = integrations[checkfrontBookingIntegrationIndex.value].integrationSettings.findIndex(setting => {
      return setting.key === 'checkfront_skus' && setting.experience && setting.experience.id === experienceId;
    });
    if (settingIndex > -1) {
      const valueArray = (add)
        ? [
          ...JSON.parse(integrations[checkfrontBookingIntegrationIndex.value].integrationSettings[settingIndex].value),
          value,
        ]
        : value;

      integrations[checkfrontBookingIntegrationIndex.value].integrationSettings[settingIndex].value = JSON.stringify(valueArray);
    } else {
      integrations[checkfrontBookingIntegrationIndex.value].integrationSettings.push({
        key: 'checkfront_skus',
        value: JSON.stringify([value]),
        experience: {
          id: experienceId,
        },
      });
    }

    updateIntegrations(integrations);
  }

  return {
    checkfrontBookingIntegrationIndex,
    checkfrontBookingIntegration,
    toggleCheckfrontBookingIntegration,
    getCheckfrontBookingIntegrationSetting,
    setCheckfrontBookingIntegrationSetting,
    getCheckfrontIntegrationSKUSetting,
    setCheckfrontIntegrationSKUSetting,
  };
}

export function useFareHarborBooking (props, updateIntegrations) {
  const defaultFareHarborIntegration = (() => {
    const fareHarborIntegration = {
      type: 'fareharbor',
      category: 'booking',
      integrationSettings: [
        {
          key: 'fareharbor_user_key',
          value: '',
        },
        {
          key: 'fareharbor_shortname',
          value: '',
        },
      ],
    };
    props.location.experiences.forEach(experience => {
      fareHarborIntegration.integrationSettings.push({
        key: 'fareharbor_items',
        value: '[]',
        experience,
      });
    });
    return fareHarborIntegration;
  })();

  const fareHarborBookingIntegrationIndex = computed(() => {
    return props.location.integrations.findIndex(integration => {
      return integration.type === 'fareharbor';
    });
  });

  const fareHarborBookingIntegration = computed(() => {
    return props.location.integrations.find(integration => {
      return integration.type === 'fareharbor';
    });
  });

  function toggleFareHarborBookingIntegration () {
    // console.log(defaultFareHarborIntegration);
    const integrations = deepmerge([], [...props.location.integrations]);
    const index = fareHarborBookingIntegrationIndex.value;
    if (index > -1) {
      integrations.splice(index, index + 1);
    } else {
      integrations.push(defaultFareHarborIntegration);
    }

    updateIntegrations(integrations);
  }

  function getFareHarborBookingIntegrationSetting (key) {
    return fareHarborBookingIntegration.value.integrationSettings.find(setting => {
      return setting.key === key;
    }).value;
  }

  function setFareHarborBookingIntegrationSetting (key, value) {
    const integrations = deepmerge([], [...props.location.integrations]);
    const settingIndex = integrations[fareHarborBookingIntegrationIndex.value].integrationSettings.findIndex(setting => {
      return setting.key === key;
    });
    if (settingIndex > -1) {
      integrations[fareHarborBookingIntegrationIndex.value].integrationSettings[settingIndex].value = value;
    } else {
      integrations[fareHarborBookingIntegrationIndex.value].integrationSettings.push({
        key,
        value,
      });
    }

    updateIntegrations(integrations);
  }

  function getFareHarborIntegrationSKUSetting (experienceId) {
    const fareHarborSKU = fareHarborBookingIntegration.value.integrationSettings.find(setting => {
      return setting.key === 'fareharbor_items' && setting.experience && setting.experience.id === experienceId;
    });
    if (fareHarborSKU) return JSON.parse(fareHarborSKU.value);
  }

  function setFareHarborIntegrationSKUSetting (experienceId, value, add = false) {
    const integrations = deepmerge([], [...props.location.integrations]);
    const settingIndex = integrations[fareHarborBookingIntegrationIndex.value].integrationSettings.findIndex(setting => {
      return setting.key === 'fareharbor_items' && setting.experience.id === experienceId;
    });
    if (settingIndex > -1) {
      const valueArray = (add)
        ? [
          ...JSON.parse(integrations[fareHarborBookingIntegrationIndex.value].integrationSettings[settingIndex].value),
          value,
        ]
        : value;

      integrations[fareHarborBookingIntegrationIndex.value].integrationSettings[settingIndex].value = JSON.stringify(valueArray);
    } else {
      integrations[fareHarborBookingIntegrationIndex.value].integrationSettings.push({
        key: 'fareharbor_items',
        value: JSON.stringify([value]),
        experience: {
          id: experienceId,
        },
      });
    }
    updateIntegrations(integrations);
  }

  return {
    fareHarborBookingIntegrationIndex,
    fareHarborBookingIntegration,
    toggleFareHarborBookingIntegration,
    getFareHarborBookingIntegrationSetting,
    setFareHarborBookingIntegrationSetting,
    getFareHarborIntegrationSKUSetting,
    setFareHarborIntegrationSKUSetting,
  };
}
