// this would create a wrapper around a modal component easily accessible within any component with ref="xxx"
// it provides a nice programatic way to fire modal with props passed as argument of show method without cluttering the parent component with data and logic for handling modal showing etc
export const createModalWrapper = (component, { props: defaultProps } = {}) => {
  const inheritedProps = component.options && component.options.props;
  return {
    name: `${(component.options && component.options.name) ||
      'Anonymous'}Wrapper`,
    props: inheritedProps,
    data: () => ({
      props: {},
      listeners: {},
      isModalVisible: false,
    }),
    methods: {
      close () {
        this.isModalVisible = false;
      },
      show (props = {}, listeners = {}) {
        this.props = {
          ...defaultProps,
          ...this.$props,
          ...props,
        };
        this.listeners = {
          ...this.$listeners,
          ...listeners,
          closeConfirm: (...args) => {
            listeners.closeConfirm && listeners.closeConfirm(...args);
            this.close();
          },
          closeModal: (...args) => {
            listeners.closeModal && listeners.closeModal(...args);
            this.close();
          },
        };
        this.isModalVisible = true;
      },
    },
    render (h) {
      return this.isModalVisible
        ? h(component, {
          props: this.props,
          on: this.listeners,
        })
        : h();
    },
  };
};
