<template>
  <Modal
    class="user-permission-model"
    ref="modal"
    :open="true"
    :prevent-scroll="true"
    @closeModal="() => $emit('closeModal')"
  >
    <div class="pa5">
      <div class="tc mb5 mt4">
        <UIIcon name="multiplayer" class="icon mb4" />
        <p class="f4 mb3">Only admins have access to the location you just created.</p>
        <p
          class="f4 instructions"
        >Give managers and staff access to this new location by updating their Location Access under Admin > Manage Users.</p>
      </div>
      <div class="flex flex-column flex-row-m items-center justify-center">
        <UIButton
          @click="() => $emit('closeModal')"
          ghost
          class="mb4 mb0-m mr4-m update-later"
        >{{ $t('Update Later') }}</UIButton>
        <UIButton
          :to="{ name: 'users' }"
          class="flex-auto-m manage-users"
        >{{ $t('Manage Users Now') }}</UIButton>
      </div>
    </div>
  </Modal>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Modal from '@/core/shared/components/Modal';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIButton from '@/core/shared/components/ui/UIButton';

@Component({
  components: {
    Modal,
    UIButton,
    UIIcon,
  },
})
export default class UserPermissionModal extends Vue {

};
</script>
<style lang="postcss" scoped>
@import "core/shared/styles";

@media (--tablet) {
  .user-permission-model {
    & >>> .panel {
      max-width: 50rem;
    }
  }
}

.icon {
  width: 6.5rem;
  fill: var(--colorFjord);
}

.update-later,
.manage-users {
  min-width: 20rem;
}

.instructions {
  font-weight: normal;
}
</style>
