<template>
  <div class="locations">
    <div class="header-bar">
      <router-link :to="{ name: 'location-show', params: { editingLocationId: 'new' } }" tag="button">{{ $t('New Location') }}</router-link>
    </div>
    <div class="location-grid" :class="{ 'more-than-three': locations.length > 3 }">
      <router-link tag="div" class="location-card" v-for="location in locations" :key="location.id" :to="{ name: 'location-show', params: { editingLocationId: location.id } }">
        <div class="image">
          <img :src="`https://maps.googleapis.com/maps/api/staticmap?center=${urlEncodedAddress(location)}&zoom=15&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative%7Celement:geometry%7Cvisibility:off&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Cvisibility:off&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit%7Cvisibility:off&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&size=400x200&maptype=roadmap&key=${$config.GOOGLE_MAPS_API_KEY}`" :alt="location.title">
          <div class="marker"></div>
        </div>
        <div class="main">
          <h1>{{ location.title }}</h1>
          <div class="address">
            <div>{{ location.address }}&nbsp;{{ location.address2 }}</div>
            <div>{{ location.city }}&nbsp;{{ location.state }}&nbsp;{{ location.postalCode }}</div>
          </div>
          <div class="number-of-stations" v-if="location.stations">
            {{ location.stations.length }}&nbsp;{{ $tc('Station | Stations', location.stations.length) }}
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component()
export default class Locations extends Vue {
  get locations () {
    return this.$store.getters['entities/Location/all']();
  }

  created () {
    this.$store.commit('setTitle', this.$t('Locations'));
  }

  urlEncodedAddress (location) {
    return encodeURIComponent(`${location.address} ${location.address2} ${location.city} ${location.state} ${location.postalCode}`);
  }
}
</script>

<style scoped lang="postcss">
@import 'core/shared/styles';

.locations {
  @apply --container;

  & .header-bar {
    margin: var(--spacingMd) 0 var(--spacingSm) 0;
    text-align: right;

    @media (--desktop) {
      margin: var(--spacingMd) 0;
    }

    & button {
      @apply --buttonPrimary;

      display: inline-block;
      width: auto;
    }
  }

  & .location-grid {
    display: grid;

    grid-template-columns: 1fr;
    grid-gap: var(--spacingSm);
    padding-bottom: var(--spacingMd);

    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (--desktop) {
      grid-template-columns: repeat(auto-fit, 33rem);

      &.more-than-three {
        grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
      }
    }

    & .location-card {
      box-shadow: var(--shadowSm);
      background-color: var(--colorWhite);
      cursor: pointer;

      & .image {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 50%;

        & img {
          top: 0;
          left: 0;
          position: absolute;
          width: 100%;
          height: 100%;
        }

        & .marker {
          box-shadow: var(--shadowSm);
          position: absolute;
          top: 50%;
          left: 50%;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background: linear-gradient(45deg, #ec4e36, #ec3679);
        }
      }

      & .main {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: var(--spacingSm);

        & h1 {
          @apply --f3;

          margin: 0;
        }

        & .address {
          @apply --f6;

          margin: 0;
          color: var(--colorManatee);
        }

        & .number-of-stations {
          @apply --f5;

          border-radius: var(--radius);
          justify-self: end;
          align-self: center;
          margin: 0;
          padding: var(--spacingXS);
          border: 0.1rem solid var(--colorFjord);
          grid-column: 2 / 3;
          grid-row: 1 / 3;
        }
      }
    }
  }
}
</style>
